import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVales(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("http://localhost:3333/api/cashadvance/", {
            params: queryParams,
          }) ///apps/vale/vales
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchVale(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`http://localhost:3333/api/cashadvance/${id}`) ///apps/vale/vales/
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/vale/clients")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
